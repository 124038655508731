import { useAuthContext } from './AuthContext';
import { Suspense, lazy, ReactNode } from 'react';

import { BrowserRouter, Routes, Route, Navigate} from'react-router-dom';

const Account = lazy(() => import("../pages/client/Account"));
const Announcement = lazy(() => import('../pages/admin/Announcement'));
const Discussion = lazy(() => import('../pages/client/Discussion'));
const Error = lazy(() => import('../pages/admin/Error'));
const Event = lazy(() => import('../pages/admin/Event'));
const Facilitator = lazy(() => import('../pages/client/Facilitator'));
const Guideline = lazy(() => import('../pages/client/Guideline'));
const Howto = lazy(() => import('../pages/client/Howto'));
const Kickoff = lazy(() => import('../pages/client/Kickoff'));
const LandingDebug = lazy(() => import('../pages/public/LandingDebug'));
const LandingHarvard = lazy(() => import('../pages/public/LandingHarvard'));
const LandingMGH = lazy(() => import('../pages/public/LandingMGH'));
const Login = lazy(() => import('../pages/public/Login'));
const Logout = lazy(() => import('../pages/public/Logout'));
const MakeUp = lazy(() => import('../pages/client/MakeUp'));
const MakeUpCheckIn = lazy(() => import('../pages/client/MakeUpCheckIn'));
const MakeUpCheckInCaseStudy = lazy(() => import('../pages/client/MakeUpCheckInCaseStudy'));
const Matching = lazy(() => import('../pages/admin/Matching'));
const MatchingSurvey = lazy(() => import('../pages/client/MatchingSurvey'));
const Overview = lazy(() => import('../pages/client/Overview'));
const PostMeeting = lazy(() => import('../pages/client/PostMeeting'));
const PostProgram = lazy(() => import('../pages/client/PostProgram'));
const ProgramEval = lazy(() => import('../pages/client/ProgramEval'));
const Reflection = lazy(() => import('../pages/client/Reflection'));
const Report = lazy(() => import('../pages/admin/Report'));
const Resource = lazy(() => import('../pages/client/Resource'));
const ResetPassword = lazy(() => import('../pages/public/ResetPassword'));
const Settings = lazy(() => import('../pages/admin/Settings'));
const Signup = lazy(() => import('../pages/public/Signup'));
const Survey = lazy(() => import('../pages/admin/Survey'));
const User = lazy(() => import('../pages/admin/User'));

const Admin = lazy(() => import('../pages/admin/Admin'));
const Home = lazy(() => import('../pages/client/Home'));

const AdminBar = lazy(() => import('../pages/admin/AdminBar'));
const UserBar = lazy(() => import('../pages/client/UserBar'));
const Loader = lazy(() => import('../components/Loader'));

interface ProtectedRouteProps {
  element: ReactNode;
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, allowedRoles }) => {
  const { user, loading } = useAuthContext();
  
  if (loading) {
    return <Loader />;
  }
  
  if (!loading && !user) {
    return <Navigate to="/login" />;
  }
  
  if (user && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {user?.role === 'admin' || user?.role === 'facilitator' ? <AdminBar /> : <UserBar />}
      {element}
    </>
  );
};
  

export default function ConvuRouter() {
  const {user} = useAuthContext()

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/mgh" element={<LandingMGH />} />
          <Route path="/harvard" element={<LandingHarvard />} />
          <Route path="/debug" element={<LandingDebug />} />
          <Route path="/" element={<ProtectedRoute element={(user?.role === 'peer') ? <Home /> : <Admin />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/account" element={<ProtectedRoute element={<Account />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/discussion" element={<ProtectedRoute element={<Discussion />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/facilitators" element={<ProtectedRoute element={<Facilitator />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/guidelines" element={<ProtectedRoute element={<Guideline />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/howto" element={<ProtectedRoute element={<Howto />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/kickoff" element={<ProtectedRoute element={<Kickoff />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/makeup" element={<ProtectedRoute element={<MakeUp />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/checkin-makeup" element={<ProtectedRoute element={<MakeUpCheckIn />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/checkin-case-study-makeup" element={<ProtectedRoute element={<MakeUpCheckInCaseStudy />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/matching-survey" element={<ProtectedRoute element={<MatchingSurvey />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/overview" element={<ProtectedRoute element={<Overview />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/post-meeting" element={<ProtectedRoute element={<PostMeeting />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/post-program" element={<ProtectedRoute element={<PostProgram />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/program-eval" element={<ProtectedRoute element={<ProgramEval />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/reflection" element={<ProtectedRoute element={<Reflection />} allowedRoles={['admin', 'facilitator', 'peer']} />} />
          <Route path="/resources" element={<ProtectedRoute element={<Resource />} allowedRoles={['admin', 'facilitator', 'peer']} />} />

          <Route path="/announcement" element={<ProtectedRoute element={<Announcement />} allowedRoles={['admin', 'facilitator']} />} />
          <Route path="/event" element={<ProtectedRoute element={<Event />} allowedRoles={['admin', 'facilitator']} />} />
          <Route path="/matching" element={<ProtectedRoute element={<Matching />} allowedRoles={['admin', 'facilitator']} />} />
          <Route path="/report" element={<ProtectedRoute element={<Report />} allowedRoles={['admin', 'facilitator']} />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} allowedRoles={['admin', 'facilitator']} />} />
          <Route path="/survey" element={<ProtectedRoute element={<Survey />} allowedRoles={['admin', 'facilitator']} />} />
          <Route path="/user" element={<ProtectedRoute element={<User />} allowedRoles={['admin', 'facilitator']} />} />
          <Route path="/user-view" element={<ProtectedRoute element={<><UserBar /><Home /></>} allowedRoles={['admin', 'facilitator']} />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};