import { UserType, UserCohortType, UserGroupType } from "../../../shared/src/User";
import { EventType } from "../../../shared/src/Event";
import { AnnouncementType } from "../../../shared/src/Announcement";
import { CertSettingType, CertSettingTypeDefaults } from "../../../shared/src/Certificate";
import { ActivityType } from "../../../shared/src/Activity";
import { useAlertContext } from './AlertContext';
import {useState, useEffect, createContext, useContext, ReactNode } from "react";
import api from "./Axios";
import { auth } from "./firebaseInit";
import { dayjsConfigured } from "../../../shared/src/dateFormatter";
import devLog from "./devLog";
import { CohortType } from "../../../shared/src/Cohort";
import { SettingType } from "../../../shared/src/Setting";

export interface AuthContextType {
  user: UserType | null;
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  users: UserType[] | null;
  setUsers: React.Dispatch<React.SetStateAction<UserType[] | null>>;
  events: EventType[] | null;
  setEvents: React.Dispatch<React.SetStateAction<EventType[] | null>>;
  announcements: AnnouncementType[] | null;
  setAnnouncements: React.Dispatch<React.SetStateAction<AnnouncementType[] | null>>;
  detailViewID: string | null;
  setDetailViewID: React.Dispatch<React.SetStateAction<string | null>>;
  certReqs: CertSettingType;
  setCertReqs: React.Dispatch<React.SetStateAction<CertSettingType>>;
  usersLoading: boolean;
  eventsLoading: boolean;
  announcementsLoading: boolean;
  filters: {users: string, events: string, announcements: string};
  setFilters: React.Dispatch<React.SetStateAction<{users: string, events: string, announcements: string}>>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { setAlert } = useAlertContext();
  const [user, setUser] = useState<UserType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<UserType[] | null>(null);
  const [events, setEvents] = useState<EventType[] | null>(null);
  const [announcements, setAnnouncements] = useState<AnnouncementType[] | null>(null);
  const [detailViewID, setDetailViewID] = useState<string | null>(null);
  const [certReqs, setCertReqs] = useState<CertSettingType>(CertSettingTypeDefaults);
  const [usersLoading, setUsersLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [certReqsLoading, setCertReqsLoading] = useState(false);
  const [announcementsLoading, setAnnouncementsLoading] = useState(false);
  const [filters, setFilters] = useState<{users: string, events: string, announcements: string}>({users: 'active', events: 'future', announcements: 'all'});

  // handle loading
  useEffect(() => {
    if (eventsLoading || announcementsLoading || usersLoading || certReqsLoading) {
      setLoading(true);
    }
    else {
      setLoading(false);
    }
  }, [eventsLoading, announcementsLoading, usersLoading, certReqsLoading]);

  // get user info
  useEffect(() => {
    setLoading(true); // Set loading to true at the start of auth check
    
    const unsubscribe = auth.onAuthStateChanged((auth_user) => {
      devLog({ message: 'AuthContext: auth onAuthStateChanged', type: 'log', optionalParams: [auth_user] });
      
      if (!auth_user) {
        devLog({ message: 'AuthContext: no user account or not logged in', type: 'info' });
        setUser(null);
        setLoading(false);
        return;
      }

      if (!user) {
        const fetchUser = async () => {
          api.get("/api/shared/user", {
            params: { id: auth_user.uid }
          })
          .then((user_doc) => {
            if (!user_doc.data) {
              // no user created yet in database during signup
              setUser(null);
              setLoading(false);
              return;
            }

            const user_data = user_doc.data;
            const time = dayjsConfigured();
            
            if (!user_data.id || !user_data.last_login) {
              devLog({ message: 'AuthContext: auth user data issue', type: 'error', optionalParams: [user_data] });
              setAlert({open: true, message: 'There was a user authentication issue.', type: 'error'});
              setLoading(false);
              return;
            }

            api.put("/api/shared/user_info", {
              id: user_data.id,
              key: 'last_login',
              value: user_data.last_login
            })
            .then(() => {
              if (user_data.role === 'admin') {

                api.get("/api/admin/groups")
                .then((groups) => {
                  devLog({ message: 'AuthContext: groups', type: 'info', optionalParams: [groups] });

                  if (!groups.data || groups.data.length === 0) {
                    setAlert({open: true, message: 'No groups found', type: 'error'});
                    setLoading(false);
                    return;
                  }
                  user_data.group = groups.data;
                  let cohorts: UserCohortType[] = [];
                  user_data.group.forEach((group: {name: string, id: string, cohorts: CohortType[]}) => {
                    group.cohorts.forEach((cohort, idx) => {
                      cohort.group = group.id;
                      cohorts.push(cohort);
                    })
                  })
                  user_data.cohort = cohorts;
                  setUser(user_data);
                  setLoading(false);
                })
                .catch ((err) => {
                  devLog({ message: 'AuthContext: Error getting groups', type: 'error', optionalParams: [err] });
                  setAlert({open: true, message: 'Error getting groups', type: 'error'});
                })
              }
              else if (user_data.role === 'facilitator') {
                api.get("/api/facilitator/cohorts", {
                  params: {
                    groups: user_data.group_list
                  }
                })
                .then((cohorts) => {
                  devLog({ message: 'AuthContext: cohorts', type: 'info', optionalParams: [cohorts] });

                  if (!cohorts.data || cohorts.data.length === 0) {
                    setAlert({open: true, message: 'No cohorts found', type: 'error'});
                    setLoading(false);
                    return;
                  }
                  user_data.cohort = cohorts.data;
                  let cohort_list = cohorts.data.map((cohort: UserCohortType) => {
                    return cohort.id;
                  });
                  user_data.cohort_list = cohort_list;
                  setUser(user_data);
                  setLoading(false);
                })
              }
              else {
                setUser(user_data);
                setLoading(false);
              }
            })
            .catch ((err) => {
              devLog({ message: 'AuthContext: Error getting user info', type: 'error', optionalParams: [err] });
              setAlert({open: true, message: 'Error getting user info', type: 'error'});
            });
          })
          .catch ((err) => {
            // cannot get user info from server even though user is verified
            devLog({ message: 'AuthContext: Error getting user info', type: 'error', optionalParams: [err] });
            setLoading(false);
            //setAlert({open: true, message: 'There was a user authentication issue.', type: 'error'});
          });
        }

        if (auth_user.metadata.creationTime === auth_user.metadata.lastSignInTime) {
          setTimeout(fetchUser, 1000);
        } else {
          fetchUser();
        }
      }
      else {
        // user !== null
        devLog({ message: 'AuthContext: user !== null', type: 'info', optionalParams: [user] });
        setLoading(false);
      }
      //} else {
        // no verified email (email is sent via login page)
      //  setLoading(false);
      //}
  });

    return () => unsubscribe();
  }, []);

  // facilitator: get users
  useEffect(() => {
    const fetchUsers = async () => {
      setUsersLoading(true);
      
      if (!user?.current_cohort || !user?.current_group) {
        setAlert({open: true, message: 'Error fetching users', type: 'error'});
        setUsers([]);
        setUsersLoading(false);
        return;
      }

      api.get("/api/facilitator/users", {
        params: {
          cohort: user.current_cohort
        }
      })
      .then((users_data_raw) => {
        let users_data = users_data_raw.data;
        if (users_data_raw.data.length === 0) {
          setUsers([]);
        } else {
          users_data = users_data
            .filter((x: UserType) => x.role === 'peer')
            .sort((a: UserType, b: UserType) => 
              a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase())
            );
          setUsers(users_data);
          devLog({ message: 'AuthContext: users', type: 'info', optionalParams: [users_data] });
        }
      })
      .catch((err) => {
        devLog({ message: 'AuthContext: Error in fetchUsers', type: 'error', optionalParams: [err] });
        setAlert({open: true, message: 'Error fetching data', type: 'error'});
        setUsers([]);
      })
      .finally(() => {
        setUsersLoading(false);
      });
    };
    
    if (user && (user.role === 'facilitator' || user.role === 'admin')) {
      fetchUsers();
    }
  }, [user?.current_cohort, user?.current_group]);

  // facilitator: get events
  useEffect(() => {
    const fetchEvents = async () => {
      setEventsLoading(true);

      if (!user?.current_cohort || !user?.current_group) {
        setAlert({open: true, message: 'Error fetching events', type: 'error'});
        setEvents([]);
        setEventsLoading(false);
        return;
      }

      api.get("/api/user/events", {
        params: {
          cohort: user.current_cohort,
          group: user.current_group
        }
      })
      .then((cohort_events) => {
        devLog({ message: 'AuthContext: cohort_events', type: 'info', optionalParams: [cohort_events?.data] });
        if (cohort_events?.data.length > 0) {
          const now = new Date();
          const events = cohort_events?.data.map((event: EventType) => ({
            ...event,
            color: event.type === 'deadline' ? 'yellow' :
              new Date(event.start_date).getTime() > now.getTime() ? 'green' : 'grey'
          }));
          events.sort((a: EventType, b: EventType) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
          setEvents(events);
        }
        else {
          setEvents([]);
        }
      })
      .catch((err) => {
        devLog({ message: 'AuthContext: Error fetching events', type: 'error', optionalParams: [err] });
        setAlert({open: true, message: 'Error getting events', type: 'error'});
        setEvents([]);
      })
      .finally(() => {
        setEventsLoading(false);
      });
    }

    if (user && (user.role === 'facilitator' || user.role === 'admin')) {
      fetchEvents();
    }
  }, [user?.current_cohort, user?.current_group]);

  // get announcements
  useEffect(() => {
    const fetchAnnouncements = async () => {
      setAnnouncementsLoading(true);
      if (!user?.current_cohort || !user?.current_group) {
        setAlert({open: true, message: 'Error fetching announcements', type: 'error'});
        setAnnouncements([]);
        return;
      }

      api.get("/api/user/announcements", {
        params: {
          cohort: user.current_cohort,
          group: user.current_group
        }
      })
      .then((response) => {
        if (user.role === 'peer') {
          const now = new Date();
          const visibleAnnouncements = response.data.filter((item: AnnouncementType) => 
            (item.display === 'manual' && item.visible) ||
            (item.display === 'timed' && 
             new Date(item.start_date).getTime() <= now.getTime() && 
             new Date(item.end_date).getTime() >= now.getTime())
          );
          setAnnouncements(visibleAnnouncements);
        } else {
          setAnnouncements(response.data);
        }
      })
      .catch((err) => {
        devLog({ message: 'AuthContext: Error fetching announcements', type: 'error', optionalParams: [err] });
        setAlert({open: true, message: 'Error getting announcements', type: 'error'});
        setAnnouncements([]);
      })
      .finally(() => {
        setAnnouncementsLoading(false);
      });
    }
    
    if (user) {
      fetchAnnouncements();
    }
  }, [user?.current_cohort, user?.current_group]);

  // peer: get events
  useEffect(() => {

    const getEventData = async () => {
      setEventsLoading(true);
      api.get("/api/user/events", {
        params: {
          cohort: user?.current_cohort,
          group: user?.current_group
        }
      })
      .then(async (res_events) => {
        let event_data = res_events.data;

        api.get("/api/shared/activities", {
          params: {
            id: user?.id
          }
        })
        .then((res_activities) => {
          let activity_data: ActivityType[] = res_activities.data;

          const now = new Date();

          event_data.forEach((event: EventType) => {
            if (event.type === 'deadline') event.color = "yellow";
            else event.color = "grey";
          })

          activity_data.forEach((activity: ActivityType) => {
            event_data.forEach((event: EventType) => {
              if (activity.id === event.id) {
                event.status = activity.status;
                if (activity.status === "attended") {
                  event.color = "blue";
                }
                else if (activity.status === "registered" && new Date(event.end_date).getTime() + (24 * 60 * 60 * 1000) < now.getTime()) {
                  event.color = "red";
                  event.status = "absent";
                }
                else if (activity.status === "registered" && new Date(event.start_date).getTime() < now.getTime() && new Date(event.end_date).getTime() + (60 * 60 * 1000) > now.getTime() ) {
                  event.color = "orange";
                }
                else if (activity.status === "registered") {
                  event.color = "green";
                }
              }
            })
          })

          event_data = event_data.filter((item: EventType) => new Date(item.end_date).getTime() > now.getTime() || 'status' in item );

          event_data.sort((a: EventType, b: EventType) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
          setEvents(event_data);
        })
        .catch((err) => {
          setAlert({open: true, message: 'Problem parsing events', type: 'error'});
        })
        .finally(() => {
          setEventsLoading(false);
        });
      })
      .catch((err) => {
        setAlert({open: true, message: 'Problem getting events', type: 'error'});
      })
    }

    if (user && user.role === 'peer') {
      if (!user.current_cohort || !user.current_group || !user.id) {
        setAlert({open: true, message: 'Error fetching events', type: 'error'});
        return;
      }
      else {
        getEventData();
      }
    };

  }, [user?.current_cohort, user?.current_group, user?.id]);

  // get cert_req
  useEffect(() => {
    const fetchCertReqs = async () => {
      setCertReqsLoading(true);
      api.get("/api/user/setting", {
        params: {
          group: user?.current_group,
          setting: 'certificate'
        }
      })
      .then((res) => {
        if (!res.data || !res.data.settings) {
          setAlert({open: true, message: 'Problem loading certificate requirements', type: 'error'});
          return;
        }
        let temp_cert = res.data;
        setCertReqs(temp_cert.settings);
      })
      .catch ((err) => {
        setAlert({open: true, message: 'Error getting certificate requirements', type: 'error'});
      })
      .finally(() => {
        setCertReqsLoading(false);
      });
    };
    
    if (user) {
      if (!user.current_group) {
        setAlert({open: true, message: 'Error fetching certificate requirements', type: 'error'});
        return;
      }
      else {
        fetchCertReqs();
      }
    }
  }, [user?.current_group]);

  const value: AuthContextType = {user, setUser, users, setUsers, events, setEvents, announcements, setAnnouncements, loading, setLoading, certReqs, setCertReqs, detailViewID, setDetailViewID, usersLoading, eventsLoading, announcementsLoading, filters, setFilters};

  return (
    <AuthContext.Provider value = {value} >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};