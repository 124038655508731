import dayjs, { ConfigType } from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const DEFAULT_TIMEZONE = "America/New_York";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

const dayjsConfigured = (date?: ConfigType | number) => {
  let dateObj;
  if (typeof date === 'number') {
    dateObj = dayjs(date);
  } else {
    dateObj = dayjs(date);
  }
  const dateInTimeZone = dateObj.tz(DEFAULT_TIMEZONE);
  return dateInTimeZone.format(DEFAULT_DATE_FORMAT);
};

const formatDateStartString = (inputDate: dayjs.Dayjs | Date | string, endString?: string): string => {
  let date: Date;

  if (dayjs.isDayjs(inputDate)) {
    date = inputDate.toDate();
  }
  else if (inputDate instanceof Date) {
    date = inputDate;
  }
  else if (typeof inputDate === 'string') {
    date = new Date(inputDate);
  }
  else {
    throw new Error("Invalid date format. Must be a Dayjs instance, Date object, or date string.");
  }

  let formattedDate = date.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: '2-digit'
  });

  if ( (endString?.includes('PM') && formattedDate.includes('PM') ) || (endString?.includes('AM') && formattedDate.includes('AM') ) ) {
    formattedDate = formattedDate.replace(' AM', '').replace(' PM', '');
  }

  return formattedDate;
};

const formatDateEndString = (inputDate: dayjs.Dayjs | Date | string): string => {
  let date: Date;

  if (dayjs.isDayjs(inputDate)) {
    date = inputDate.toDate();
  }
  else if (inputDate instanceof Date) {
    date = inputDate;
  }
  else if (typeof inputDate === 'string') {
    date = new Date(inputDate);
  }
  else {
    throw new Error("Invalid date format. Must be a Dayjs instance, Date object, or date string.");
  }

  let formattedDate = date.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: '2-digit'
  });

  return formattedDate;
};

const formatDateFullString = (inputDate: dayjs.Dayjs | Date | string): string => {
  let date: Date;

  if (dayjs.isDayjs(inputDate)) {
    date = inputDate.toDate();
  }
  else if (inputDate instanceof Date) {
    date = inputDate;
  }
  else if (typeof inputDate === 'string') {
    date = new Date(inputDate);
  }
  else {
    throw new Error("Invalid date format. Must be a Dayjs instance, Date object, or date string.");
  }

  let formattedDate = date.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });

  return formattedDate;
};

export { dayjsConfigured, dayjs, formatDateStartString, formatDateEndString, formatDateFullString };
