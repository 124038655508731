import axios from "axios";
import {auth} from "./firebaseInit";
import devLog from "./devLog";

const getUserToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (auth_user) => {
      unsubscribe();
      if (auth_user) {
        auth_user.getIdToken()
        .then((token) => {
          resolve(token);
        })
        .catch((err) => {
          reject(err);
        });
      }
      else {
        devLog({ message: 'Axios: getUserToken', type: 'error', optionalParams: [auth_user] });
        reject(new Error('No user is logged in.'));
      }
    });
  });
};

let baseURL = import.meta.env.VITE_BASE_URL;

if (import.meta.env.VITE_MODE === "staging" || import.meta.env.VITE_MODE === "staging-local") {
  baseURL = `${baseURL}/staging`;
}

const api = axios.create({
  paramsSerializer: {
    indexes: null
  },
  baseURL
});

api.interceptors.request.use( async (config: any) => {
  devLog({ message: `API Request: ${config.method?.toUpperCase()} ${config.url}`, type: 'log',
    optionalParams: [ config.baseURL,
      { data: config.data, params: config.params, headers: config.headers },
    ],
  });

  if (config.url.includes('/api/public')) {
    return config;
  }
  else {
    return getUserToken()
      .then((token) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      })
      .catch((error) => {
        devLog({ message: 'Axios: Failed to get token for request', type: 'error', optionalParams: [error] });
        return Promise.reject(error);
      });
    }
  },
  (error) => {
    devLog({ message: 'Axios: Request error', type: 'error', optionalParams: [error] });
    return Promise.reject(error)
  }
);

api.interceptors.response.use( (response) => {
    devLog({ message: `API Response: ${response.config.method?.toUpperCase()} ${response.config.url}`, type: 'log',
      optionalParams: [
        {
          status: response.status,
          data: response.data,
          headers: response.headers,
        },
      ],
    });

    return response;
  },
  (error) => {
    if (error.response) {
      devLog({ message: `API Response Error: ${error.response.config.method?.toUpperCase()} ${error.response.config.url}`, type: 'error' });
    }
    else {
      devLog({ message: 'Axios: Unknown response error', type: 'error', optionalParams: [error] });
    }
    return Promise.reject(error);
  }
);

export default api;