interface DevLogParams {
  message: string;
  type: 'log' | 'error' | 'info' | 'warn';
  optionalParams?: any[];
}

const devLog = ({ message, type = 'log', optionalParams = [] }: DevLogParams) => {
  if (import.meta.env.VITE_MODE === 'development' || import.meta.env.VITE_MODE === 'staging' || import.meta.env.VITE_MODE === 'staging-local') {
    switch (type) {
      case 'log':
        console.log(message, ...optionalParams);
        break;
      case 'error':
        console.error(message, ...optionalParams);
        break;
      case 'info':
        console.info(message, ...optionalParams);
        break;
      case 'warn':
        console.warn(message, ...optionalParams);
        break;
      default:
        console.log(message, ...optionalParams);
    }
  }
};

export default devLog;