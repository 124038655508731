import { createContext, useContext, useState, ReactNode } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
  screenSize: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
  };
}
declare module '@mui/material/styles' {
  interface Palette {
    row_background: {
      blue: string;
      yellow: string;
      grey: string;
    };
  }
  interface PaletteOptions {
    row_background?: {
      blue?: string;
      yellow?: string;
      grey?: string;
    };
  }
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isDesktop = useMediaQuery('(min-width: 1025px)');

  const [theme, setTheme] = useState(createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1976d2', // Custom primary color
        dark: '#0056b3', // Custom dark color for primary
      },
      secondary: {
        main: '#0c1e67', // Custom secondary color
        // '#0c1e67'
        //'#d27619'
      },
      row_background: {
        blue: '#ebf5fb',
        yellow: 'lightyellow',
        grey: '#efefef'
      }
    },
  }));

  const toggleTheme = () => {
    setTheme((prevTheme) => createTheme({
      palette: {
        mode: prevTheme.palette.mode === 'light' ? 'dark' : 'light',
      },
    }));
  };

  return (
    <ThemeContext.Provider value={{
      theme,
      toggleTheme,
      screenSize: {
        isMobile,
        isTablet,
        isDesktop,
      },
    }}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within an ThemeProvider');
  }
  return context;
};