import { SettingType } from "./Setting.js";

export interface CertSettingType {
  check_in: SettingType;
  completion: SettingType;
  kickoff: SettingType;
  kickoff_response: SettingType;
  office_hours: SettingType;
  program_eval: SettingType;
  reflection: SettingType;
}

export const CertSettingTypeDefaults: CertSettingType = {
  check_in: {value_max: 0, value_min: 0, value: '', user_note: '', title: ''},
  completion: {value_max: 0, value_min: 0, value: '', user_note: '', title: ''},
  kickoff: {value_max: 0, value_min: 0, value: '', user_note: '', title: ''},
  kickoff_response: {value_max: 0, value_min: 0, value: false, user_note: '', title: ''},
  office_hours: {value_max: 0, value_min: 0, value: '', user_note: '', title: ''},
  program_eval: {value_max: 0, value_min: 0, value: false, user_note: '', title: ''},
  reflection: {value_max: 0, value_min: 0, value: '', user_note: '', title: ''},
};

export interface CertType { 
  check_in: number;
  completion: number;
  kickoff: number;
  kickoff_response: boolean;
  office_hours: number;
  program_eval: boolean;
  reflection: number;
 };

 export const CertTypeDefaults: CertType = {
  check_in: 0,
  completion: 0,
  kickoff: 0,
  kickoff_response: false,
  office_hours: 0,
  program_eval: false,
  reflection: 0
 }